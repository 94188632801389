import HuntGroupService from '@/services/hunt.group.service';
import UserService from '@/services/user.service';

const huntGroupService = new HuntGroupService(),
    userService = new UserService();

export default {
    name: 'create',
    components: {},
    props: [],
    data() {
        return {
            agents: [],

            agentSelected: null,
            agentGroupSelected: null,

            huntGroup: {
                users: [],
                name: '',
                policy: 'PARALLEL',
                noAnswer: {
                    forwardAfterWaitingEnabled: false,
                    forwardAfterWaiting: 0,
                    forwardNumberEnabled: false,
                    forwardNumber: ''
                }
            },
        }
    },
    computed: {},
    mounted() {
        this.list();
        userService.myAgents(1000).then(res => {
            if (res && !res.error) {
                this.agents = res.data;
            }
        }).catch(null);

    },
    methods: {

        nameLetters(name) {
            const name_words = name.split(' ');
            let result = "";
            for (const word of name_words) {
                result += word.charAt(0).toUpperCase();
            }

            return result;
        },

        async resetFormFields() {
            this.customer = {...this.customerCopy};
        },

        async cancelModal() {
            this.createdModal = false;
            this.validation = {};
            this.resetFormFields();
        },

        async list(page) {
            this.currentPage = page || 1;
            let skip = this.perPage * (this.currentPage - 1);

            const res = await huntGroupService.list(this.perPage, skip, this.searchVal);
            if (res && !res.error) {
                this.groups = res.data.groups;
                this.totalRows = res.data.count;
            }
        },

        async setPerPage(num) {
            this.perPage = +(num);
            this.list();
        },

        add(agent) {

            const index = this.agents.findIndex(a => a._id === agent._id);

            if (index >= 0) {
                this.huntGroup.users.push(this.agents[index]);
                this.agents.splice(index, 1);
            }

        },

        remove(user) {

            const index = this.huntGroup.users.findIndex(a => a._id === user._id);

            if (index >= 0) {
                this.agents.push(this.huntGroup.users[index]);
                this.huntGroup.users.splice(index, 1);
            }

        },

        addAll() {
            for (const user of this.agents) {
                this.huntGroup.users.push(user);
            }

            this.agents = [];
        },

        removeAll() {
            for (const user of this.huntGroup.users) {
                this.agents.push(user);
            }

            this.huntGroup.users = [];
        },

        async save() {
            const res = await huntGroupService.create(this.huntGroup);

            if (res && !res.error)
                this.$router.go();
        }
    }
}
