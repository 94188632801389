import {required} from 'vuelidate/lib/validators'

export default {
    huntGroup: {
        name: {
            required,
        },
        forwardNumber: {
            required,
        },
    },
    errors: {
        name: {
            required: 'Name is required',
            titleIf: 'Name is required'
        },
        forwardNumber: {
            required: 'Forward Number is required',
            titleIf: 'Forward Number is required'
        },
    }
}