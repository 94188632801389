import HuntGroupService from '@/services/hunt.group.service';
import UserService from '@/services/user.service';
import CreateHuntGroup from './Create/index.vue';
import Validations from './validation';

const huntGroupService = new HuntGroupService(),
    userService = new UserService();


export default {
  name: 'hunt-groups',

  components: { CreateHuntGroup },

  data() {
    return {
      groups: [],
      createdModal: false,
      updateModal: false,
      deleteModal : {
        Shown: false,
        Group: null
      },
      agents: [],
      agentsCopy: [],

      agentSelected: null,
      agentGroupSelected: null,

      huntGroup: {
        users: [],
        name: '',
        policy: 'PARALLEL',
        noAnswer: {
          forwardAfterWaitingEnabled: false,
          forwardAfterWaiting: 0,
          forwardNumberEnabled: false,
          forwardNumber: ''
        }
      },
      errors:{},
      huntGroupCopy: {},
      searchVal: '',
      perPage: 10,
      totalRows: 0,
      currentPage: 1
    }
  },

  validations: Validations,

  mounted() {
    this.list();
    userService.myAgents(1000).then(res => {
      if (res && !res.error) {
        this.agents = res.data;
        this.agentsCopy = res.data;
      }
    }).catch(null);
    this.huntGroupCopy = this.huntGroup;
  },

  methods: {
    async cancelModal() {
      this.createdModal = false;
      this.updateModal = false;
      this.huntGroup = this.huntGroupCopy;
      this.agents = this.agentsCopy.slice();
      this.errors = {};
      this.huntGroup.noAnswer.forwardAfterWaitingEnabled = false;
      this.huntGroup.noAnswer.forwardNumberEnabled = false;
    },

    async list(page) {
      this.currentPage = page || 1;
      let skip = this.perPage * (this.currentPage - 1);

      const res = await huntGroupService.list(this.perPage, skip, this.searchVal);
      if (res && !res.error) {
        this.groups = res.data.groups;
        this.totalRows = res.data.count;
      }
    },

    add(agent) {

      const index = this.agents.findIndex(a => a._id === agent._id);

      if (index >= 0) {
        this.huntGroup.users.push(this.agents[index]);
        this.agents.splice(index, 1);
      }

    },

    remove(user) {

      const index = this.huntGroup.users.findIndex(a => a._id === user._id);

      if (index >= 0) {
        this.agents.push(this.huntGroup.users[index]);
        this.huntGroup.users.splice(index, 1);
      }

    },

    addAll() {
      for (const user of this.agents) {
        this.huntGroup.users.push(user);
      }

      this.agents = [];
    },

    removeAll() {
      for (const user of this.huntGroup.users) {
        this.agents.push(user);
      }

      this.huntGroup.users = [];
    },

    async save() {
      let res = null;
      if(this.updateModal === true) {
        res = await huntGroupService.edit(this.huntGroup);
      } else {
        res = await huntGroupService.create(this.huntGroup);
      }

      if (res && !res.error) {
        this.list(this.currentPage || 1);
        this.cancelModal();
      }

    },

    async setPerPage(num) {
      this.perPage = +(num);
      this.list();
    },

    async edit(group) {
      this.updateModal = true;
      this.agents = this.agentsCopy.slice();
      this.huntGroup = group;
      for(let user of this.huntGroup.users) {
        this.agents = this.agents.filter(ele => ele._id !== user._id);
      }
    },

    showRemove(group){
      this.deleteModal.Group = group;
      this.deleteModal.Shown = true;
    },

    async removeGroup(){
      this.$showLoading();
      const res = await huntGroupService.remove(this.deleteModal.Group._id);
      if ( res && !res.error ) {
        const index = this.groups.findIndex(g => g._id === this.deleteModal.Group._id);
        if ( index > -1 ) {
          this.groups.splice(index, 1);
        }
        this.deleteModal.Shown = false;
      }
      this.$hideLoading();
    },

    async ValidateForm(){

      let flag = true;
      var copy ={};
      for(let key in this.$v.huntGroup){
        let $el = this.$v.huntGroup[key] !== undefined && this.$v.huntGroup[key].$invalid !== undefined && this.$v.huntGroup[key].$invalid === true  ? this.$v.huntGroup[key] :undefined
        if($el && key !== 'forwardNumber'){
          if($el.required !== undefined && !$el.required)
            copy[key] = Validations.errors[key].required;
          else
          if(key)
            for(let[k, val] of Object.entries($el)){
              if(!/^\$/.test(k) && !val){
                copy[key] = Validations.errors[key][k];
              }
            }
          flag = false
        }
        if(this.huntGroup.noAnswer.forwardNumberEnabled === true && this.huntGroup.noAnswer.forwardNumber === '' && key === 'forwardNumber') {
          if(key)
            for(let[k, val] of Object.entries($el)){
              if(!/^\$/.test(k) && !val){
                copy[key] = Validations.errors[key][k];
              }
            }
          flag = false
        }
      }
      this.errors = copy;
      if(flag)
        await this.save();
    },

    next() {
      this.list(this.currentPage + 1);
    },

    prev() {
      this.list(this.currentPage - 1);
    },
  },

  watch: {
    perPage() {
      this.list();
    }
  },
}

